import React from 'react'
import {Field, reduxForm} from 'redux-form'

import styles from './styles.scss'

const StatementRow = (acc, sr) => {
  let ctn = sr.catalogName
  if(acc.rows.length > 0 && acc.catalogName == ctn)
    ctn = ''
  let amnt = sr.amountDiff.toLocaleString('jp-JP')
  if(isNaN(amnt[0])) {
    amnt = '−' + amnt.slice(1) //actual minus sign
  }
  let row =
    <tr key={acc.rows.length} data-isnewcatalog={ctn != ''}>
      <td>{ctn}</td>
      <td>{sr.itemName}</td>
      <td>{sr.nItems}</td>
      <td>￥{amnt}</td>
    </tr>
  acc.rows.push(row)
  return {rows: acc.rows, catalogName: sr.catalogName}
}

const Form = props => {
  const { handleSubmit, pristine } = props
  let rows = props.statement.reduce(StatementRow, {rows: [], catalogName: ''}).rows
  let label = '口座振込み申請を行う'
  let label_finished = ''
  let total = props.statement.reduce((a, x) => a + x.amountDiff, 0)
  if(!props.canApply) label_finished += ' (既に申し込み済み)'
  const actions = (<div>
    <button className={styles.buttonSecondary} onClick={props.back}>戻る</button>
    <button type='submit' disabled={pristine}>次へ</button>
  </div>)
  if (props.cbStatementError) {
    return (
      <main className={styles.orderDetails}>
          <p>現在、差額キャッシュバック明細を集計中です。時間を空けて再度アクセスください。<br/>
            数日経ってもこのメッセージが表示され続ける場合、大変お手数ですがお問い合わせください。</p>
          <form onSubmit={handleSubmit}>
            <div></div>
            {actions}
          </form>
      </main>
    )
  }


  return <main className={styles.orderDetails}>
    <label>キャッシュバック明細をご確認ください</label>

    <div>
      <span>キャッシュバック合計：</span>
      <span>{total.toLocaleString("jp-JP")} pt</span>
    </div>
    <table>
      <tbody>
        <tr><th>カタログプラン</th><th>商品名</th><th>申込数</th><th>差額合計（税込）</th></tr>
        {rows}
      </tbody>
    </table>
    <label>ポイントの利用方法を選択してください</label>
    <form onSubmit={handleSubmit}>
      <div>
        <Field id='i1' name='action' value='apply_cashback' component='input' type='radio' disabled={!props.canApply} />
        <label htmlFor='i1'> {label} <span>{label_finished}</span> </label>
        <Field id='i2' name='action' value='buy_catalog' component='input' type='radio' disabled='true' />
        <label htmlFor='i2'>追加のカタログギフトを購入する</label>
      </div>
      <div readOnly='true'>
        追加購入をする機能は現在準備中でございます。
        <br />
        ご迷惑をおかけしますが、口座振込申請をご利用ください。
      </div>
      {actions}
    </form>
  </main>
}

export default reduxForm({
  form: 'orderDetailAction'
})(Form)
