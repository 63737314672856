import React from 'react'

import styles from './styles.scss'

export default class TopBar extends React.Component {
  render() {
    return <header className={styles.topbar}>
      <img src='/assets/logo.png' />
      <span>{this.props.groom}様・{this.props.bride}様　注文情報及びキャッシュバック管理ページ</span>
      <button className={styles.buttonSecondary} onClick={this.props.logout}>サインアウト</button>
    </header>
  }
}
