import React from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import {destroy, startSubmit, stopSubmit} from 'redux-form'

import {ProjectPage} from './ProjectPageContainer.jsx'

import {CashbackApplicationForm, Confirm, Finished} from '../components/CashbackApplicationForm.jsx'

import {apply, withToken} from '../utils'

import {PATH_HOME, PATH_ORDER_DETAILS, PATH_PRIVACY_POLICY, toProjectPage} from '../map.js'

import {STATUS_FINISHED, STATUS_WAITING} from '../reducers/apiReducers.js'

class Container extends React.Component {

  componentDidMount() {
    this.props.resetCbStatusR()
    this.props.resetPutCbR()
    this.props.hardReset()
    this.props.getDoesCashbackApplicationExist()
    this.props.getStatement()
  }

  componentDidUpdate() {
    if(this.props.putCashbackApplicationR.status == STATUS_WAITING) {
      this.props.startFormSubmit('cbApplicationFinal')
    } else {
      this.props.stopFormSubmit('cbApplicationFinal')
    }
    if(this.props.getDoesCashbackApplicationExistR.status == STATUS_FINISHED && this.props.getDoesCashbackApplicationExistR.res)
      this.props.history.push(PATH_HOME)
    if(this.props.putCashbackApplicationR.error) {
      window.alert('エラーが発生しました。再度お試しください。')
      this.props.resetPutCbR()
    }
  }

  render() {

    if(this.props.putCashbackApplicationR.status == STATUS_FINISHED && !this.props.putCashbackApplicationR.error)
      return <Finished toTop={() => this.props.history.push(PATH_HOME)} />

    let onConfirm = values => {
      if(values.privacyPolicy)
        this.props.putCbApplication(this.props.input.values)
    }
    if(this.props.input.submitSucceeded) {
      return <Confirm
        pPolicy={PATH_PRIVACY_POLICY}
        cashback={{
          ...this.props.input.values,
          amount: this.props.amount,
          amountYen: this.props.amount}}
        onSubmit={(values) => { this.props.startFormSubmit('cbApplicationFinal') ; onConfirm(values)}}
        goBack={this.props.softReset} />
    }
    else
      return <CashbackApplicationForm
        onSubmit={() => {}}
        goBack={() => { return this.props.history.push(toProjectPage(this.props.project.projectId, PATH_ORDER_DETAILS))}}/>
  }
}

const mapDispatchToProps = (dispatch, { token, project: { projectId } }) => {
  return {
    startFormSubmit: (form) => dispatch(startSubmit(form)),
    stopFormSubmit: (form) => dispatch(stopSubmit(form)),
    getDoesCashbackApplicationExist: () => dispatch({type: 'GET_DOES_CASHBACK_APPLICATION_EXIST_R', token, projectId}),
    resetCbStatusR: () => dispatch({type: 'GET_DOES_CASHBACK_APPLICATION_EXIST_R_RESET'}),
    getStatement: () => dispatch({type: 'GET_CASHBACK_STATEMENT_R', token, projectId}),
    putCbApplication: (bankInfo) => dispatch({type: 'PUT_CASHBACK_APPLICATION_R', token, projectId, bankInfo}),
    resetPutCbR: () => dispatch({type: 'PUT_CASHBACK_APPLICATION_R_RESET'}),
    softReset: () => dispatch({type: 'SOFT_RESET_CB_APPLICATION_FORM'}),
    hardReset: () => { dispatch(destroy('cbApplication')); dispatch(destroy('cbApplicationFinal'))}
  }
}

const mapStateToProps = ({getDoesCashbackApplicationExistR, form, getCashbackStatementR, putCashbackApplicationR}) => {
  return {
    getDoesCashbackApplicationExistR,
    putCashbackApplicationR,
    input: form.cbApplication || {},
    amount: (getCashbackStatementR.statement || []).reduce((a, x) => a+x.amountDiff, 0)
  }
}

export default [connect(mapStateToProps, mapDispatchToProps), withToken, withRouter, ProjectPage].reduce(apply, Container)
