
// @flow
import storage from 'redux-persist/lib/storage'
import type {Action} from '../actions'

export const STATUS_INIT: string = 'INIT'
export const STATUS_WAITING: string = 'WAITING'
export const STATUS_FINISHED: string = 'FINISHED'

export type State_POST_LOGIN_R = { token: ?string, expiresAt: ?string,  error: ?string, status: string, response: ?any }

const initialState_POST_LOGIN_R = {
  token: null, expiresAt: null,  error: null, status: 'INIT', response: null
};

export const postLoginR = (state: State_POST_LOGIN_R = initialState_POST_LOGIN_R, a: Action) => {
  switch (a.type) {
    case 'POST_LOGIN_R':
      return { ...state, status: STATUS_WAITING }
    case 'POST_LOGIN_R_SUCCESS':
      return { ...state, status: STATUS_FINISHED, response: a.response, token: a.token, expiresAt: a.expiresAt };
    case 'POST_LOGIN_R_ERROR':
      return { ...state, status: STATUS_FINISHED, error: a.error, response: a.response };
    case 'POST_LOGIN_R_RESET':
      return { ...state, status: STATUS_INIT, error: null}
    default:
      return state;
  }
};


export type State_GET_USERINFO_R = { userInfo: ?{ groomName: ?string, brideName: ?string, email1: ?string, email2: ?string, email3: ?string },  error: ?string, status: string, response: ?any }

const initialState_GET_USERINFO_R = {
  userInfo: null,  error: null, status: 'INIT', response: null
};

export const getUserinfoR = (state: State_GET_USERINFO_R = initialState_GET_USERINFO_R, a: Action) => {
  switch (a.type) {
    case 'GET_USERINFO_R':
      return { ...state, status: STATUS_WAITING }
    case 'GET_USERINFO_R_SUCCESS':
      return { ...state, status: STATUS_FINISHED, response: a.response, userInfo: a.userInfo };
    case 'GET_USERINFO_R_ERROR':
      return { ...state, status: STATUS_FINISHED, error: a.error, response: a.response };
    case 'GET_USERINFO_R_RESET':
      return { ...state, status: STATUS_INIT, error: null}
    default:
      return state;
  }
};


export type State_GET_MY_PROJECTS_R = { projects: ?Array<{ projectId: string, orderType: string, isPrimary: bool, isCbReady: bool, isOrderListRequestReady: bool, lastOrderListRequest: Date }>,  error: ?string, status: string, response: ?any }

const initialState_GET_MY_PROJECTS_R = {
  projects: null,  error: null, status: 'INIT', response: null
};

export const getMyProjectsR = (state: State_GET_MY_PROJECTS_R = initialState_GET_MY_PROJECTS_R, a: Action) => {
  switch (a.type) {
    case 'GET_MY_PROJECTS_R':
      return { ...state, status: STATUS_WAITING }
    case 'GET_MY_PROJECTS_R_SUCCESS':
      return { ...state, status: STATUS_FINISHED, response: a.response, projects: a.projects };
    case 'GET_MY_PROJECTS_R_ERROR':
      return { ...state, status: STATUS_FINISHED, error: a.error, response: a.response };
    case 'GET_MY_PROJECTS_R_RESET':
      return { ...state, status: STATUS_INIT, error: null}
    default:
      return state;
  }
};


export type State_GET_PROJECT_R = { project: ?{ projectId: string, orderType: string, isPrimary: bool, isCbReady: bool, isOrderListRequestReady: bool, lastOrderListRequest: Date },  error: ?string, status: string, response: ?any }

const initialState_GET_PROJECT_R = {
  project: null,  error: null, status: 'INIT', response: null
};

export const getProjectR = (state: State_GET_PROJECT_R = initialState_GET_PROJECT_R, a: Action) => {
  switch (a.type) {
    case 'GET_PROJECT_R':
      return { ...state, status: STATUS_WAITING }
    case 'GET_PROJECT_R_SUCCESS':
      return { ...state, status: STATUS_FINISHED, response: a.response, project: a.project };
    case 'GET_PROJECT_R_ERROR':
      return { ...state, status: STATUS_FINISHED, error: a.error, response: a.response };
    case 'GET_PROJECT_R_RESET':
      return { ...state, status: STATUS_INIT, error: null}
    default:
      return state;
  }
};


export type State_POST_ORDER_LIST_REQUEST_R = {  error: ?string, status: string, response: ?any }

const initialState_POST_ORDER_LIST_REQUEST_R = {
   error: null, status: 'INIT', response: null
};

export const postOrderListRequestR = (state: State_POST_ORDER_LIST_REQUEST_R = initialState_POST_ORDER_LIST_REQUEST_R, a: Action) => {
  switch (a.type) {
    case 'POST_ORDER_LIST_REQUEST_R':
      return { ...state, status: STATUS_WAITING }
    case 'POST_ORDER_LIST_REQUEST_R_SUCCESS':
      return { ...state, status: STATUS_FINISHED, response: a.response };
    case 'POST_ORDER_LIST_REQUEST_R_ERROR':
      return { ...state, status: STATUS_FINISHED, error: a.error, response: a.response };
    case 'POST_ORDER_LIST_REQUEST_R_RESET':
      return { ...state, status: STATUS_INIT, error: null}
    default:
      return state;
  }
};


export type State_GET_CASHBACK_STATEMENT_R = { statement: ?Array<{ catalogName: string, itemName: string, nItems: number, amountDiff: number }>,  error: ?string, status: string, response: ?any }

const initialState_GET_CASHBACK_STATEMENT_R = {
  statement: null,  error: null, status: 'INIT', response: null
};

export const getCashbackStatementR = (state: State_GET_CASHBACK_STATEMENT_R = initialState_GET_CASHBACK_STATEMENT_R, a: Action) => {
  switch (a.type) {
    case 'GET_CASHBACK_STATEMENT_R':
      return { ...state, status: STATUS_WAITING }
    case 'GET_CASHBACK_STATEMENT_R_SUCCESS':
      return { ...state, status: STATUS_FINISHED, response: a.response, statement: a.statement };
    case 'GET_CASHBACK_STATEMENT_R_ERROR':
      return { ...state, status: STATUS_FINISHED, error: a.error, response: a.response };
    case 'GET_CASHBACK_STATEMENT_R_RESET':
      return { ...state, status: STATUS_INIT, error: null}
    default:
      return state;
  }
};


export type State_GET_DOES_CASHBACK_APPLICATION_EXIST_R = { res: ?bool,  error: ?string, status: string, response: ?any }

const initialState_GET_DOES_CASHBACK_APPLICATION_EXIST_R = {
  res: null,  error: null, status: 'INIT', response: null
};

export const getDoesCashbackApplicationExistR = (state: State_GET_DOES_CASHBACK_APPLICATION_EXIST_R = initialState_GET_DOES_CASHBACK_APPLICATION_EXIST_R, a: Action) => {
  switch (a.type) {
    case 'GET_DOES_CASHBACK_APPLICATION_EXIST_R':
      return { ...state, status: STATUS_WAITING }
    case 'GET_DOES_CASHBACK_APPLICATION_EXIST_R_SUCCESS':
      return { ...state, status: STATUS_FINISHED, response: a.response, res: a.res };
    case 'GET_DOES_CASHBACK_APPLICATION_EXIST_R_ERROR':
      return { ...state, status: STATUS_FINISHED, error: a.error, response: a.response };
    case 'GET_DOES_CASHBACK_APPLICATION_EXIST_R_RESET':
      return { ...state, status: STATUS_INIT, error: null}
    default:
      return state;
  }
};


export type State_PUT_CASHBACK_APPLICATION_R = {  error: ?string, status: string, response: ?any }

const initialState_PUT_CASHBACK_APPLICATION_R = {
   error: null, status: 'INIT', response: null
};

export const putCashbackApplicationR = (state: State_PUT_CASHBACK_APPLICATION_R = initialState_PUT_CASHBACK_APPLICATION_R, a: Action) => {
  switch (a.type) {
    case 'PUT_CASHBACK_APPLICATION_R':
      return { ...state, status: STATUS_WAITING }
    case 'PUT_CASHBACK_APPLICATION_R_SUCCESS':
      return { ...state, status: STATUS_FINISHED, response: a.response };
    case 'PUT_CASHBACK_APPLICATION_R_ERROR':
      return { ...state, status: STATUS_FINISHED, error: a.error, response: a.response };
    case 'PUT_CASHBACK_APPLICATION_R_RESET':
      return { ...state, status: STATUS_INIT, error: null}
    default:
      return state;
  }
};

