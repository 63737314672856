import React from 'react'

import {canRequestOrderList} from '../utils'

import styles from './styles.scss'

var sortMap = {
  "引き出物": 0,
  "内祝い": 1,
  "プレゼント・賠償": 2
}

class ProjectLine extends React.Component {
  render() {
    let toOLR = () => {return this.props.toOLR(this.props.project.projectId)}
    let toCB = () => {return this.props.toCB(this.props.project.projectId)}
    let ptype = this.props.project.orderType == 'プレゼント・賠償' ? 'その他' : this.props.project.orderType
    return <div>
      <h3>{this.props.needHeader ? `ご注文 ${this.props.pid + 1}: ${ptype}` : ''}</h3>
      <button disabled={!canRequestOrderList(this.props.project)} onClick={toOLR}>注文者リストのリクエスト</button>
      <button disabled={!this.props.project.isCbReady} onClick={toCB}>キャッシュバック申請</button>
    </div>
  }
}

class Component extends React.Component {

  render() {
    let projs = this.props.projects.sort((x, y) => {
      return sortMap[x.orderType] - sortMap[y.orderType]
    })
    projs = projs.reduce((a, p) => {
      let pid = a.length
      a.push(<ProjectLine key={pid} pid={pid} project={p} toOLR={this.props.toOLR} toCB={this.props.toCB} needHeader={projs.length > 1}  />)
      return a
    }, [])
    return <main className={styles.myHome}>
      <label>メニューを選択してください</label>
      {projs}
      <ul className={styles.notes}>
      <li>引き出物お届け希望日の約2週間以降に、注文者リストのリクエストをご利用いただけます。</li>
      <li>挙式日から4ヶ月以降にキャッシュバック申請をご利用いただけます。</li>
      <li>一度注文者リストのリクエストを行うと、翌00:00まで注文者リストのリクエストはご利用いただけません。</li>
      </ul>
    </main>
  }
}

export default Component
