import React from 'react'
import {connect} from 'react-redux'
import {reduxForm, Field, formValueSelector} from 'redux-form'

import styles from './styles.scss'
const isIE = /*@cc_on!@*/false || !!document.documentMode

const acctypes = {
  'Normal': '普通',
  'Checking': '当座'
}

const selector = formValueSelector('cbApplicationFinal')

const Form = props => {

  const {handleSubmit, submitting} = props

  return (<main className={isIE ? styles.cashbackApplicationConfirmIE : styles.cashbackApplicationConfirm}>
    <form onSubmit={handleSubmit}>
      <label>振込情報をご確認ください</label>
      <label>利用ポイント</label><label>{props.cashback.amount.toLocaleString('jp-JP')} pt</label>
      <label>振込額</label><label>{props.cashback.amountYen.toLocaleString('jp-JP')}円</label>
      <label>銀行名</label><label>{props.cashback.bankName}</label>
      <label>支店名</label><label>{props.cashback.branchName}</label>
      <label>口座種別</label><label>{acctypes[props.cashback.accType]}</label>
      <label>口座番号</label><label>{props.cashback.accNumber}</label>
      <label>口座名義</label><label>{props.cashback.accName}</label>
      <div>
        <Field id='privacyPolicy' component='input' name='privacyPolicy' type='checkbox' required />
        <label htmlFor='privacyPolicy'><a href={props.pPolicy} target='_blank' rel='noopener noreferrer'>プライバシーポリシー</a>に同意する</label>
      </div>
      <button className={styles.buttonSecondary} disabled={submitting} onClick={props.goBack} type='button'>戻る</button>
      <button type='submit' disabled={submitting || !props.agreed}>この内容で申請する</button>
    </form>
  </main>)
}

const mapStateToProps = state => {
  return {
    agreed: selector(state, 'privacyPolicy')
  }
}

export default connect(mapStateToProps)(reduxForm({
  form: 'cbApplicationFinal'
})(Form))
