import React from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'

import { withProjectId, withToken, apply } from '../utils'

import { PATH_HOME } from '../map.js'
import Loading from '../components/Loading.jsx'
import {MyPage} from './MyPageContainer.jsx'

let wrap = comp => class extends React.Component {

  componentDidMount() {
    this.props.resetR()
    this.props.getProjectRequest()
  }

  componentDidUpdate() {
    if(this.props.getProjectR.error)
      this.props.history.push(PATH_HOME)
  }

  render() {
    let Comp = comp
    if(this.props.getProjectR.status != 'FINISHED')
      return <Loading />
    else if(this.props.error) {
      return <div></div>
    }
    else {
      return <Comp project={this.props.getProjectR.project} />
    }
  }
}

const mapStateToProps = ({getProjectR}) => {
  return {
    getProjectR
  }
}

const mapDispatchToProps = (dispatch, {token, projectId}) => {
  return {
    getProjectRequest: () => {
      dispatch({type: 'GET_PROJECT_R', token, projectId})
    },
    resetR: () => {
      dispatch({type: 'GET_PROJECT_R_RESET'})
    }
  }
}

export const ProjectPage = comp => {
  return [wrap, connect(mapStateToProps, mapDispatchToProps), withProjectId, withRouter, withToken, MyPage].reduce(apply, comp)
}
