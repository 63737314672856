// @flow

import { reducer as reduxFormReducer } from 'redux-form';

import type {Action} from '../actions'

export type State = { token: ?string }

const initialState = {
  token: null,
  checkedToken: false
};

export const auth = (state: State = initialState, a: Action) => {
  switch (a.type) {
    case 'RECEIVE_TOKEN':
      return { ...state, token: a.token, checkedToken: true };
    default:
      return state;
  }
};

export const form = reduxFormReducer.plugin({
  cbApplication: (state, action) => {
    switch(action.type) {
      case 'SOFT_RESET_CB_APPLICATION_FORM':
        return {...state, submitSucceeded: false}
      default:
        return state
    }
  }
})
