export const PATH_HOME = '/'
export const PATH_LOGIN = '/login'
export const PATH_ORDER_DETAILS = '/details'
export const PATH_CASHBACK_APPLICATION = '/cashback-application'
export const PATH_ORDER_LIST_REQUEST = '/order-list-request'
export const PATH_PRIVACY_POLICY = '/privacy-policy'

export const toProjectPage = (pid, page) => {
  return '/' + pid + page
}
