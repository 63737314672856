import React from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'

import {ProjectPage} from './ProjectPageContainer.jsx'
import Loading from '../components/Loading.jsx'

import CashbackRequest from '../components/CashbackRequest.jsx'

import {apply, withToken} from '../utils'

import { PATH_HOME, PATH_CASHBACK_APPLICATION, toProjectPage } from '../map.js'

function onSubmit(vals, props) {
  if(vals.action == 'apply_cashback')
    return props.history.push(toProjectPage(props.project.projectId, PATH_CASHBACK_APPLICATION))
}

class Container extends React.Component {

  componentDidMount() {
    this.props.getDoesCashbackApplicationExist()
    this.props.requestStatement()
  }

  componentDidUpdate() {
    if(this.props.getCashbackStatementR.error) {
      window.alert(this.props.getCashbackStatementR.error)
      this.props.history.push(PATH_HOME)
    }
  }

  render() {
    if(this.props.getCashbackStatementR.status == 'FINISHED') {
      return <CashbackRequest
        canApply={this.props.canApply}
        onSubmit={(vals) => onSubmit(vals, this.props)}
        statement={this.props.statement || []}
        back={() => { return this.props.history.push(PATH_HOME)}}
        cbStatementError={this.props.getCashbackStatementR.error} />
    }
    else {
      return <Loading />
    }
  }
}

const mapDispatchToProps = (dispatch, { token, project: { projectId } }) => {
  return {
    getDoesCashbackApplicationExist: () => dispatch({type: 'GET_DOES_CASHBACK_APPLICATION_EXIST_R', token, projectId}),
    requestStatement: () => dispatch({type:'GET_CASHBACK_STATEMENT_R', token, projectId})
  }
}

const mapStateToProps = (state) => {
  return {
    canApply: !state.getDoesCashbackApplicationExistR.res,
    statement: state.getCashbackStatementR.statement,
    getCashbackStatementR: state.getCashbackStatementR
  }
}

export default [connect(mapStateToProps, mapDispatchToProps), withToken, withRouter, ProjectPage].reduce(apply, Container)
