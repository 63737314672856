import React from 'react'

import styles from './styles.scss'

class Component extends React.Component {

  render() {
    return <div className={styles.errorServerDown}>
      <span>申し訳ございません。</span>
      <span>現在サーバーがに問題があるため、ご利用になれません。<br /> 後ほど再度お試しください。</span>
    </div>
  }
}

export default Component
