import {
  takeLatest
} from 'redux-saga'

import {
  call,
  put
} from 'redux-saga/effects'

import {
} from 'react-router-redux'

import * as api from './api.js'

const ERR_MSG_ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND'
const ERR_MSG_ACCOUNT_NOT_CONFIRMED = 'ACCOUNT_NOT_CONFIRMED'
const ERR_MSG_ACCOUNT_EXPIRED = 'ACCOUNT_EXPIRED'

const ERR_ACCOUNT_EXPIRED = '申し訳ありません。お客様の挙式日から180日間以上が経過しているため、サインインできません。'
const ERR_GENERIC = 'エラーが発生しました。'
const ERR_401_GENERIC = '認証に失敗しました。お手数ですが、再度サインインください。。'
const ERR_401_LOGIN = 'お客様ID、またはパスワードが間違っています。ご確認の上、再度お試しください。'
const ERR_ACCOUNT_NOT_CONFIRMED = `申し訳ございません。
現在アカウントの発行作業中のためマイページをご利用いただけません。
発行は10営業日以内に完了いたしますので、後日お試しください。
その後問題が発生する場合は、大変お手数ですが直接お問い合わせください。`
const ERR_NO_CONNECTION = '申し訳ございません。サーバーに接続できませんでした。'
const ERR_CB_STATEMENT_UNAVAILABLE = '申し訳ございません。現在キャッシュバックを作成中です。後ほど再度お試しください。'

const invalidateToken = () => {
  localStorage.removeItem('token')
  return put({
    type: 'RECEIVE_TOKEN',
    token: null
  })
}

function* getToken() {
  const token = localStorage.getItem('token')
  yield put({
    type: 'RECEIVE_TOKEN',
    token
  })
}

function* setToken({
  token
}) {
  localStorage.setItem('token', token)
  yield put({
    type: 'RECEIVE_TOKEN',
    token
  })
}

const handleError = (err) => {
  let error
  if (!err.response) error = ERR_NO_CONNECTION
  else switch (err.response.status) {
  case 401: {
    error = err.response.data == ERR_MSG_ACCOUNT_NOT_FOUND ? ERR_401_LOGIN : ERR_401_GENERIC
    return {
      action: invalidateToken(),
      error
    }
  }
  case 403: {
    switch(err.response.data) {
    case ERR_MSG_ACCOUNT_EXPIRED:
      return {
        action: invalidateToken(),
        error: ERR_ACCOUNT_EXPIRED
      }
    case ERR_MSG_ACCOUNT_NOT_CONFIRMED:
      return {
        action: invalidateToken(),
        error: ERR_ACCOUNT_NOT_CONFIRMED
      }
    default:
      return { error: ERR_GENERIC }
    }
  }
  default:
    return {
      error: ERR_GENERIC
    }
  }
}

function* postLoginR({
  userId,
  password
}) {
  try {
    const token = yield call(api.postLogin, userId, password)
    yield put({
      type: 'POST_LOGIN_R_SUCCESS',
      token
    })
    yield put({
      type: 'SET_TOKEN',
      token
    })
  } catch (ex) {
    let {
      action,
      error
    } = handleError(ex)
    if (action) yield action
    yield put({
      type: 'POST_LOGIN_R_ERROR',
      error,
      response: ex.response
    })
  }
}

function* getUserinfoR({
  token
}) {
  try {
    const userInfo = yield call(api.whoamiAuth, token)
    yield put({
      type: 'GET_USERINFO_R_SUCCESS',
      userInfo
    })
  } catch (ex) {
    let {
      action,
      error
    } = handleError(ex)
    if (action) yield action
    yield put({
      type: 'GET_USERINFO_R_ERROR',
      error,
      response: ex.response
    })
  }
}

function* getMyProjectsR({
  token
}) {
  try {
    const projects = yield call(api.getMyProjectsAuth, token)
    yield put({
      type: 'GET_MY_PROJECTS_R_SUCCESS',
      projects
    })
  } catch (ex) {
    let {
      action,
      error
    } = handleError(ex)
    if (action) yield action
    yield put({
      type: 'GET_MY_PROJECTS_R_ERROR',
      error,
      response: ex.response
    })
  }
}

function* getProjectR({
  token,
  projectId
}) {
  try {
    const project = yield call(api.getProjectAuth, token, projectId)
    yield put({
      type: 'GET_PROJECT_R_SUCCESS',
      project
    })
  } catch (ex) {
    let {
      action,
      error
    } = handleError(ex)
    if (action) yield action
    yield put({
      type: 'GET_PROJECT_R_ERROR',
      error,
      response: ex.response
    })
  }
}

function* postOrderListRequestR({
  token,
  projectId
}) {
  try {
    yield call(api.postOrderListRequestAuth, token, projectId)
    yield put({
      type: 'POST_ORDER_LIST_REQUEST_R_SUCCESS'
    })
  } catch (ex) {
    let {
      action,
      error
    } = handleError(ex)
    if (action) yield action
    if (error) yield put({
      type: 'POST_ORDER_LIST_REQUEST_R_ERROR',
      error,
      response: ex.response
    })
  }
}

function* getCashbackStatementR({
  token,
  projectId
}) {
  try {
    const statement = yield call(api.getProjectCashbackStatementAuth, token, projectId)
    yield put({
      type: 'GET_CASHBACK_STATEMENT_R_SUCCESS',
      statement
    })
  } catch (ex) {
    let {
      action,
      error
    } = handleError(ex)
    if (action) yield action
    yield put({
      type: 'GET_CASHBACK_STATEMENT_R_ERROR',
      error: ex.response && ex.response.status == 404 ? ERR_CB_STATEMENT_UNAVAILABLE : error,
      response: ex.response
    })
  }
}

function* putCashbackApplicationR({
  token,
  projectId,
  bankInfo
}) {
  try {
    yield call(api.putProjectCbApplicationAuth, token, projectId, bankInfo)
    yield put({
      type: 'PUT_CASHBACK_APPLICATION_R_SUCCESS'
    })
  } catch (ex) {
    let {
      action,
      error
    } = handleError(ex)
    if (action) yield action
    yield put({
      type: 'PUT_CASHBACK_APPLICATION_R_ERROR',
      error,
      response: ex.response
    })
  }
}

function* getDoesCashbackApplicationExistR({
  token,
  projectId
}) {
  try {
    const res = yield call(api.getDoesCashbackApplicationExistAuth, token, projectId)
    yield put({
      type: 'GET_DOES_CASHBACK_APPLICATION_EXIST_R_SUCCESS',
      res
    })
  } catch (ex) {
    let {
      action,
      error
    } = handleError(ex)
    if (action) yield action
    yield put({
      type: 'GET_DOES_CASHBACK_APPLICATION_EXIST_R_ERROR',
      error,
      response: ex.response
    })
  }
}

function* Saga() {
  yield takeLatest('GET_TOKEN', getToken)
  yield takeLatest('SET_TOKEN', setToken)
  yield takeLatest('DROP_TOKEN', invalidateToken)

  yield takeLatest('POST_LOGIN_R', postLoginR)
  yield takeLatest('GET_USERINFO_R', getUserinfoR)
  yield takeLatest('GET_PROJECT_R', getProjectR)
  yield takeLatest('GET_MY_PROJECTS_R', getMyProjectsR)
  yield takeLatest('POST_ORDER_LIST_REQUEST_R', postOrderListRequestR)
  yield takeLatest('GET_CASHBACK_STATEMENT_R', getCashbackStatementR)
  yield takeLatest('PUT_CASHBACK_APPLICATION_R', putCashbackApplicationR)
  yield takeLatest('GET_DOES_CASHBACK_APPLICATION_EXIST_R', getDoesCashbackApplicationExistR)
}

export default Saga