import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TopBar from '../components/TopBar.jsx'
import Loading from '../components/Loading.jsx'
import ErrorServerDown from '../components/ErrorServerDown.jsx'

import { withToken, apply } from '../utils'
import {STATUS_FINISHED} from '../reducers/apiReducers.js'

let wrap = child => class extends React.Component {

  componentDidMount() {
    this.props.getUserInfo()
  }

  render() {
    if(this.props.getUserInfoR.error && !this.props.getUserInfoR.response) {
      return <ErrorServerDown />
    }
    else if(this.props.getUserInfoR.status != STATUS_FINISHED) {
      return <Loading />
    }
    let Child = child
    return <React.Fragment>
      <TopBar bride={this.props.userInfo.brideName} groom={this.props.userInfo.groomName} logout={this.props.logout}/>
      <Child />
    </React.Fragment>
  }
}

const mapStateToProps = ({getUserinfoR}) => {
  return {
    userInfo: getUserinfoR.userInfo || {},
    getUserInfoR: getUserinfoR
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUserInfo: () => dispatch({type: 'GET_USERINFO_R', token: props.token}),
    logout: () => dispatch({type: 'DROP_TOKEN'})
  }
}

export const MyPage = child => {
  return [wrap, connect(mapStateToProps, mapDispatchToProps), withRouter, withToken].reduce(apply, child)
}
