import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import MyHome from '../components/MyHome.jsx'

import {apply, withToken} from '../utils'
import {
  toProjectPage,
  PATH_ORDER_DETAILS,
  PATH_ORDER_LIST_REQUEST
} from '../map.js'

function redirect(props, dest) {
  props.history.push(dest)
}

class Container extends React.Component {

  componentDidMount() {
    this.props.getMyProjects()
  }

  componentDidUpdate() {
    if(this.props.getMyProjectsR.error) {
      window.alert('エラーが発生しました。再度お試しください')
    }
  }

  render() {
    const toOLR = (pid) => { return redirect(this.props, toProjectPage(pid, PATH_ORDER_LIST_REQUEST)) }
    const toCB = (pid) => { return redirect(this.props, toProjectPage(pid, PATH_ORDER_DETAILS)) }
    return <MyHome projects={this.props.projects} toOLR={toOLR} toCB={toCB} />
  }
}

const mapStateToProps = ({getMyProjectsR}) => {
  return {
    getMyProjectsR,
    projects: getMyProjectsR.projects || []
  }
}

const mapDispatchToProps = (dispatch, {token}) => {
  return {
    getMyProjects: () => dispatch({type: 'GET_MY_PROJECTS_R', token})
  }
}

export default [connect(mapStateToProps, mapDispatchToProps), withRouter, withToken].reduce(apply, Container)
