import React from 'react'
import {Field, reduxForm} from 'redux-form'

import styles from './styles.scss'
const isIE = false || !!document.documentMode

const Form = props => {
  const { handleSubmit, pristine, submitting } = props
  return <main className={isIE ? styles.loginIE : styles.login}>
    <img src='./assets/logo.png' />
    <label>マイページ</label>
    <form onSubmit={handleSubmit}>
      <label>お客様ID</label>
      <Field name='userId' component='input' type='text' required/>
      <label>パスワード</label>
      <Field name='password' component='input' type='password' required/>
      <button type='submit' disabled={pristine || submitting}>サインイン</button>
    </form>
    <label id="error">{props.theError}</label>
  </main>
}

export const Login = reduxForm({
  form: 'cbApplication',
  destroyOnUnmount: false
})(Form)
