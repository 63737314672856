import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router, Switch, Route} from 'react-router'
import { createBrowserHistory } from 'history'


import configureStore from './configureStore'
import * as smap from './map'

import ProtectedRoute from './containers/ProtectedRoute.jsx'
import { MyPage } from './containers/MyPageContainer.jsx'
import Login from './containers/LoginContainer.jsx'
import Home from './containers/Home.jsx'
import OrderListRequest from './containers/OrderListRequestContainer.jsx'
import CashbackRequest from './containers/CashbackRequestContainer.jsx'
import CashbackApplication from './containers/CashbackApplicationContainer.jsx'
import PrivacyPolicy from './components/static/PrivacyPolicy.jsx'

import '../assets/app.scss'

const store = configureStore()
const history = createBrowserHistory()

render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route exact path={smap.PATH_LOGIN} component={Login} />
        <Route exact path={smap.PATH_PRIVACY_POLICY} component={PrivacyPolicy} />
        <ProtectedRoute exact path='/' component={MyPage(Home)} />
        <ProtectedRoute exact path={'/:id' + smap.PATH_ORDER_LIST_REQUEST} component={OrderListRequest} />
        <ProtectedRoute exact path={'/:id' + smap.PATH_ORDER_DETAILS} component={CashbackRequest} />
        <ProtectedRoute exact path={'/:id' + smap.PATH_CASHBACK_APPLICATION} component={CashbackApplication} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('app')
)

//
// Development mode
//
if (location.hash === '#development') {
  document.body.className += ' development'
}
