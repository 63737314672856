import 'babel-polyfill'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import reducers from './reducers'
import rootSaga from './sagas'

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = process.env.NODE_ENV == 'production' ?
    applyMiddleware(
      sagaMiddleware
    ) :
    applyMiddleware(
      sagaMiddleware,
      createLogger({collapsed:true})
    )
  const store = createStore(
    combineReducers({
      ...reducers
    }), middlewares)
  sagaMiddleware.run(rootSaga)

  return store
}
