import axios from 'axios'


const isIE = /*@cc_on!@*/false || !!document.documentMode

const API_HOST = process.env.NODE_ENV == 'production' ?
  'https://api.stock.selects.jp' :
  isIE ? '10.0.2.2:8090' : 'http://localhost:8090'

const makeGet = (path, cfg) => axios.get(`${API_HOST}${path}`, cfg).then(x => x.data)
const makePut = (path, data, cfg) => axios.put(`${API_HOST}${path}`, data, cfg).then(x => x.data)
const makePost = (path, data, cfg) => axios.post(`${API_HOST}${path}`, data, cfg).then(x => x.data)

const makePostAuth = (path, token, data, cfg) => makePost(path, data, makeAuthCfg(token, cfg))
const makeGetAuth = (path, token, cfg) => makeGet(path, makeAuthCfg(token, cfg))
const makePutAuth = (path, token, data, cfg) => makePut(path, data, makeAuthCfg(token, cfg))


export const postLogin = (userId, password) => makePost('/users/login', { userId: userId, password: password })
export const whoamiAuth = token => makeGetAuth('/users/me', token)
export const getMyProjectsAuth = (token) => makeGetAuth('/users/me/projects', token)
export const getProjectAuth = (token, pid) => makeGetAuth(toProj(pid, ''), token)
export const getProjectCbApplicationInfoAuth = (token, pid) => makeGetAuth(toProj(pid, '/cb-application'), token)
export const putProjectCbApplicationAuth = (token, pid, bankInfo) => makePutAuth(toProj(pid, '/cb-application'), token, bankInfo)
export const postOrderListRequestAuth = (token, pid) => makePostAuth(toProj(pid, '/order-list-request'), token)
export const getDoesCashbackApplicationExistAuth = (token, pid) => makeGetAuth(toProj(pid, '/cb-application'), token)
export const getProjectCashbackStatementAuth = (token, pid) => makeGetAuth(toProj(pid, '/cb-statement'), token)


const toProj = (pid, rest) => { return '/projects/' + pid + rest }

const makeAuthCfg = (token, cfg) => {
  let h = cfg ? cfg.headers : {}
  return {
    ...cfg,
    headers: {
      ...h,
      'Authorization': 'Bearer ' + token
    }
  }
}
