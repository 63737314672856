import React from 'react'
import {Field, reduxForm} from 'redux-form'

import CBAConfirm from './CashbackApplicationConfirm.jsx'

import styles from './styles.scss'
const isIE = /*@cc_on!@*/false || !!document.documentMode

export const Confirm = CBAConfirm

export class Finished extends React.Component {
  render() {
    return <main className={styles.cashbackApplicationFinished}>
      <span>口座振込申請を受け付けました。</span>
      <p>１ヶ月以内にご登録いただいた口座にお振込いたします。</p>
      <button onClick={this.props.toTop}>トップへ</button>
    </main>
  }
}

const Form = props => {
  const { handleSubmit, pristine, submitting } = props
  return (<form className={isIE ? styles.cashbackApplicationIE : styles.cashbackApplication} onSubmit={handleSubmit}>
    <label>口座情報を入力してください</label>
    <label htmlFor='f1'>銀行名</label>
    <Field id='f1' name='bankName' component='input' type='text' placeholder='〇〇銀行' required />
    <label htmlFor='f2'>支店名</label>
    <Field id='f2' name='branchName' component='input' type='text' placeholder='〇〇支店' required />
    <label>口座種別</label>
    <div>
      <Field id='f3' name='accType' component='input' type='radio' value='Normal' required />
      <label htmlFor='f3'>普通</label>
      <Field id='f4' name='accType' component='input' type='radio' value='Checking' required />
      <label htmlFor='f4'>当座</label>
    </div>

    <div className={styles.formLabels}>
      <label htmlFor='f5'>口座番号</label>
      <label>半角数字</label>
    </div>
    <Field id='f5' name='accNumber' component='input' type='text' pattern='[0-9]+' placeholder='123456' required />
    <div className={styles.formLabels}>
      <label htmlFor='f6'>口座名義</label>
      <label>全角カナ</label>
    </div>
    <Field id='f6' name='accName' component='input' type='text' pattern="^[ア-ンヴァ-ャＡ-Ｚ０-９ー（）／．ー　]*$" placeholder='レモタロウ' required />
    <ul>
      <li>口座名義は必ず新郎様・新婦様のお名前をご記入ください。<br />もしそれが難しい場合は、弊社へご連絡ください。</li>
    </ul>
    <div>
      <button className={styles.buttonSecondary} onClick={props.goBack} type='button'>戻る</button>
      <button type='submit' disabled={pristine || submitting}>次へ</button>
    </div>
  </form>)
}

export const CashbackApplicationForm = reduxForm({
  form: 'cbApplication',
  destroyOnUnmount: false
})(Form)
