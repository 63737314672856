import React from 'react'
import {Route, withRouter} from 'react-router'
import { connect } from 'react-redux'

import Loading from '../components/Loading.jsx'

import {PATH_LOGIN} from '../map.js'
import {apply} from '../utils'

const Wrap = Child => class extends React.Component {
  componentDidMount() {
    this.props.requestOfflineToken()
  }

  componentDidUpdate() {
    if(this.props.checkedToken && !this.props.token) {
      this.props.history.push(PATH_LOGIN)
    }
  }

  render() {
    if(!this.props.token)
      return <Loading />
    return <Child />
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    checkedToken: auth.checkedToken,
    token: auth.token
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestOfflineToken: () => dispatch({type: 'GET_TOKEN'})
  }
}

class Container extends React.Component {
  render() {
    let Child = [Wrap, connect(mapStateToProps, mapDispatchToProps), withRouter].reduce(apply, this.props.component)
    return <Route {...this.props} component={Child} />
  }
}


export default Container
