import React from 'react'

import styles from './styles.scss'

export class Finished extends React.Component {
  render() {
    return <main className={styles.orderListRequestFinished}><span>リクエストを受け付けました</span><button onClick={this.props.goBack}>マイページトップへ</button></main>
  }
}

export class OrderListRequest extends React.Component {
  render() {
    let emails = this.props.emails.reduce((a, x) => { a.push(<li key={x}>{x}</li>); return a }, [])

    return <main className={styles.orderListRequest}>
      <span>注文者リストのリクエストを行いますか？</span>
      <span>リクエストを行うと、翌日 00:00 に登録されたメールアドレスへリストを送信します。</span>
      <span>登録メールアドレス : </span>
      <ul>
        {emails}
      </ul>
      <ul>
        <li>カタログギフトは商品注文の権利を譲渡して利用可能です。そのためご存知ないお届け先氏名が上記リストに掲載されている可能性があります。
        </li>
        <li>
        申込みハガキを送付頂いてからシステム反映まで一定日数頂いております。そのため、すでにお申し込み頂いている場合でも上記リストに、まだ反映されない可能性があります。
        </li>
        <li>
        申込みハガキを送付頂いてからシステム反映まで一定日数頂いております。そのため、すでにお申し込み頂いている場合でも上記リストに、まだ反映されない可能性があります。
        </li>
        <li>
        差額キャッシュバック集計対象は、申込みハガキ記載の有効期限（3ヶ月間）となります。上記リストに掲載されていても、有効期限以降のご注文に関しては差額キャッシュバックの対象にはなりませんのでご了承ください。
        </li>
        <li>
        一度リクエストを行うと、翌日 00:00 までリクエストを行うことができません。
        </li>
      </ul>
      <div>
        <button disabled={this.props.submitting} onClick={this.props.makeOrderListRequest}>注文者リストリクエストを行う</button>
        <button className={styles.buttonSecondary} onClick={this.props.back}>戻る</button>
      </div>
    </main>
  }
}
