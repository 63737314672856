import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {OrderListRequest, Finished} from '../components/OrderListRequest.jsx'
import {ProjectPage} from './ProjectPageContainer.jsx'

// importing directly from ./reducers causes statuses to become undefined for some reason
import {STATUS_FINISHED, STATUS_WAITING} from '../reducers/apiReducers.js'
import {apply, withToken, canRequestOrderList} from '../utils'

import {PATH_HOME} from '../map.js'

class Container extends React.Component {

  componentDidMount() {
    this.props.resetR()
  }

  componentDidUpdate() {
    if(!this.props.project) {
      this.props.history.push(PATH_HOME)
    }
    if(this.props.project && !canRequestOrderList(this.props.project)) {
      this.props.history.push(PATH_HOME)
    }
    if(this.props.postOrderListRequestR.error) {
      window.alert(this.props.postOrderListRequestR.error)
      this.props.resetR()
    }
  }

  render() {
    if(this.props.postOrderListRequestR.status == STATUS_FINISHED && !this.props.postOrderListRequestR.error) {
      return <Finished goBack={() => {this.props.history.push(PATH_HOME)}}/>
    }
    else {
      return <OrderListRequest
        emails={[this.props.project.email1, this.props.project.email2, this.props.project.email3]}
        makeOrderListRequest={() => { this.props.request()}}
        back={() => this.props.history.push(PATH_HOME)}
        submitting={this.props.postOrderListRequestR.status == STATUS_WAITING}/>
    }
  }
}

const mapDispatchToProps = (dispatch, {token, project: { projectId }}) => {
  return {
    request: () => dispatch({type: 'POST_ORDER_LIST_REQUEST_R', token, projectId}),
    resetR: () => dispatch({type: 'POST_ORDER_LIST_REQUEST_R_RESET'})
  }
}

const mapStateToProps = ({ postOrderListRequestR }) => {
  return {
    postOrderListRequestR: postOrderListRequestR
  }
}

export default [connect(mapStateToProps, mapDispatchToProps), withToken, withRouter, ProjectPage].reduce(apply, Container)
