import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {Login} from '../components/Login.jsx'

import * as smap from '../map.js'

import {apply} from '../utils'

class Container extends React.Component {

  componentDidMount() {
    this.props.requestOfflineToken()
  }

  componentDidUpdate() {
    if(this.props.token) {
      this.props.history.push(smap.PATH_HOME)
    }
    if(this.props.postLoginR.error) {
      // this.props.resetR()
      // window.alert(this.props.postLoginR.error)
    }
  }

  render() {
    return <Login 
      onSubmit={values => this.props.postLogin(values.userId, values.password)}
      theError={this.props.postLoginR.error}/>
  }
}

const mapStateToProps = (state) => {
  return {
    postLoginR: state.postLoginR,
    checkedToken: state.auth.checkedToken,
    token: state.auth.token
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postLogin: (userId, password) => {
      dispatch({type: 'POST_LOGIN_R_RESET'})
      dispatch({type: 'POST_LOGIN_R', userId, password})
    },
    resetR: () => {
      dispatch({type: 'POST_LOGIN_R_RESET'})
    },
    requestOfflineToken: () => { dispatch({type: 'GET_TOKEN'}) }
  }
}

export default [connect(mapStateToProps, mapDispatchToProps), withRouter].reduce(apply, Container)
