import {
  connect
} from 'react-redux'

function mapTokenToProps(state) {
  return {
    token: state.auth.token
  }
}

function mapProjectIdToProps(state, props) {
  let l = props.location.pathname
  let pid = l.substring(1, l.substring(1).indexOf('/') + 1)
  return {    
    projectId: pid
  }
}

export const withToken = (comp) => {
  return connect(mapTokenToProps)(comp)
}

export const withProjectId = (comp) => {
  return connect(mapProjectIdToProps)(comp)
}

export const apply = (a, f) => {
  return f(a)
}

export const canRequestOrderList = (project) => {
  let hasNoRecent = true
  if(project.lastOrderListRequest) {
    let d1 = new Date(project.lastOrderListRequest)
    let d2 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate() + 1, 0, 0, 0, 0)
    let now = Date.now()
    hasNoRecent = now > d2
  }
  return hasNoRecent && project.isOrderListRequestReady
}