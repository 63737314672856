import React from 'react'

import styles from './styles.scss'

class Component extends React.Component {

  render() {
    return <div className={styles.progressLoader}></div>
  }
}

export default Component
